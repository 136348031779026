* {
    font-family: "Montserrat", "Roboto", "Arial" !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*-moz-user-select: none;*/
    /*-webkit-user-select: none;*/
    /*-ms-user-select:none;*/
    /*user-select:none;*/
    /*-o-user-select:none;*/
}

* p {
    font-size: 17px;
    line-height: 25px; /* 3px +17px + 3px */
}
* li {
    font-size: 17px;
    line-height: 20px;
}

body {
    min-width: 320px !important;
    min-height: 640px !important;
}

section {
    height: 100vh;
    width: 100%;
}

.invisible {
    opacity: 0;
}

.display-none {
    display: none;
}

a {
    text-decoration: none;
}
/*
    Exact aspect ratio, put it at the bottom to avoid override.
    Optimize site for wide/ultra-wide monitors.
    All monitors should be with 16:9 aspect ratio.
*/
@media (min-aspect-ratio: 19/9) and (min-width: 1920px) {
    body {
        width: 100vw;
        height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
        max-height: 100vh !important;
        max-width: 177.78vh !important; /* 16/9 = 1.778 */
        margin: auto;
        top:0; bottom:0; /* vertical center */
        left:0; right:0; /* horizontal center */
    }

    nav {
        max-width: 177.78vh !important; /* 16/9 = 1.778 */
    }
}

