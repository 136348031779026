.full-width-with-text {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    background: #5089C6;
    width: 100%;

    display: flex;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    flex-direction: column;
}
.full-width-with-text:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2),
            rgba(255,255,255,0.1) 2px,
            rgba(255,255,255,0.1) 5px,
            rgba(255,255,255,0.1) 5px
    );
}
.full-width-with-text-container {
    position: relative;
    height: 100%;
    width: 75vw !important;
    min-width: 75vw !important;
    max-width: 75vw !important;
}
.full-width-with-text-container p {
    margin-top: 5px;
    margin-bottom: 20px;
    color: white !important;
    text-align: center;
}
.full-width-with-text-container .icon {
    position: relative;
    z-index: 2;
    left: calc(50% - 21px);
    margin-top: -11px;
    width: 40px;
    height: 40px;
    color: white !important;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.4));
}