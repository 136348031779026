.categories-page-section {
    min-height: 75vh;
    height: 100%;
    width: 100%;
    margin-top: 10px;
}
.categories-page-content {
    height: 100%;
    min-height: 75vh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}
/*.custom-categories-sticky-header {*/
/*    display: none;*/
/*}*/

.categories-page-switch-items {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    max-width: 177.5vh;
    height: 5vh;
    margin-top: 25px;
    margin-bottom: 150px;
    flex-wrap: wrap;
    width: 75vw;
}
.categories-page-switch-item-wrap {
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4)) !important;
    width: calc(75vw / 5);
    height: fit-content;
}
.categories-page-switch-item {
    clip-path: polygon(75% 0%, 95% 50%, 75% 100%, 0% 100%, 20% 50%, 0% 0%);
    background: #26649E;
    height: 4vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 10px;
    font-weight: 600;
}
.categories-page-switch-item:hover {
    transform: scale(1.05);
    transition: 0.4s;
}

.categories-page-content-top {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 30vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.categories-page-content-top img {
    position: absolute;
    z-index: -1;
    height: 100vh;
    min-width: 100vw;
    aspect-ratio: 1.77;
    left: 0;
    top: 0;
    filter: brightness(40%) invert(1);
}
.categories-page-top-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.categories-page-top-item {
    position: relative;
    z-index: 1;
    background: rgba(255,255,255, 0.7);
    color: black;
    /*width: fit-content;*/
    width: calc(65vw / 4);
    height: 20vh;

}
.categories-page-top-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.categories-page-top-item-content-top {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.categories-page-top-item-content-top i {
    font-size: 52px;
    color: #26649E;
}
.categories-page-top-item-content-bottom {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.categories-page-top-item-content-bottom p {
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}