@media (max-width: 870px) {
    .application-container {
        width: 100vw !important;
        margin-top: 0;
        margin-bottom: 0;
    }
    .application-input-pair {
        flex-direction: column !important;
    }
    .application-message-box {
        width: 100%;
    }
    .application-input-pair-column {
        width: 100%;
    }
}