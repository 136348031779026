.conditions-roadmap-items {
    width: 75vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 10px;
    grid-auto-flow: row;
    grid-template-areas:
    "conditions-raodmap-item11 conditions-raodmap-item12 conditions-raodmap-item13 conditions-raodmap-item14 conditions-raodmap-item15 conditions-raodmap-item16"
    "conditions-raodmap-item21 conditions-raodmap-item22 conditions-raodmap-item23 conditions-raodmap-item24 conditions-raodmap-item25 conditions-raodmap-item26";
    min-height: 25vh;
    margin-bottom: 50px;
    margin-top: 40px;
}

.conditions-raodmap-item11 {
    grid-area: conditions-raodmap-item11;
    border-bottom: #223D6A solid 10px;
    background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #223D6A calc(50% - 1px),
            #223D6A calc(50% + 1px),
            transparent calc(50% + 1px),
            transparent 100%
    );
}
.conditions-raodmap-item11 div {
    border-color: #223D6A;
    background: #223D6A;
}
.conditions-raodmap-item21 { grid-area: conditions-raodmap-item21;
    border-top: #223D6A solid 10px;
}

.conditions-raodmap-item12 {
    grid-area: conditions-raodmap-item12;
    border-bottom: #26649E solid 10px;
}
.conditions-raodmap-item22 {
    grid-area: conditions-raodmap-item22;
    border-top: #26649E solid 10px;
    background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #26649E calc(50% - 1px),
            #26649E calc(50% + 1px),
            transparent calc(50% + 1px),
            transparent 100%
    );
}
.conditions-raodmap-item22 div {
    border-color: #26649E;
    background: #26649E;
}

.conditions-raodmap-item13 {
    grid-area: conditions-raodmap-item13;
    border-bottom: #287BE9 solid 10px;
    background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #287BE9 calc(50% - 1px),
            #287BE9 calc(50% + 1px),
            transparent calc(50% + 1px),
            transparent 100%
    );
}
.conditions-raodmap-item-logo {
    border-color: #287BE9;
    background: #287BE9;
}
.conditions-raodmap-item23 {
    grid-area: conditions-raodmap-item23;
    border-top: #287BE9 solid 10px;
}

.conditions-raodmap-item14 {
    grid-area: conditions-raodmap-item14;
    border-bottom: black solid 10px;
}
.conditions-raodmap-item24 {
    grid-area: conditions-raodmap-item24;
    border-top: black solid 10px;
    background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            black calc(50% - 1px),
            black calc(50% + 1px),
            transparent calc(50% + 1px),
            transparent 100%
    );
}
.conditions-raodmap-item24 div {
    border-color: black;
    background: black;
}

.conditions-raodmap-item15 {
    grid-area: conditions-raodmap-item15;
    border-bottom: dimgray solid 10px;
    background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            dimgray calc(50% - 1px),
            dimgray calc(50% + 1px),
            transparent calc(50% + 1px),
            transparent 100%
    );
}
.conditions-raodmap-item15 div {
    border-color: dimgray;
    background: dimgray;
}
.conditions-raodmap-item25 {
    grid-area: conditions-raodmap-item25;
    border-top: dimgray solid 10px;
}

.conditions-raodmap-item16 {
    grid-area: conditions-raodmap-item16;
    border-bottom: darkgrey solid 10px;

}
.conditions-raodmap-item26 {
    grid-area: conditions-raodmap-item26;
    border-top: darkgrey solid 10px;
    background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            darkgrey calc(50% - 1px),
            darkgrey calc(50% + 1px),
            transparent calc(50% + 1px),
            transparent 100%
    );
}
.conditions-raodmap-item26 div {
    border-color: darkgrey;
    background: darkgrey;
}

.conditions-raodmap-item-icon {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
}
.conditions-raodmap-item-icon-align-top {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
}
.conditions-raodmap-item-icon-align-bottom {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-end;
}
.conditions-raodmap-item div {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.conditions-raodmap-item div .icon {
    width: 22px;
    height: 22px;
    color: white !important;
}
.conditions-roadmap-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    background: repeating-linear-gradient(
            -45deg,
            white,
            #EFEFEF 1px,
            #EFEFEF 1px,
            #EFEFEF 7px
    );
    border-radius: 5%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    padding: 10px;

}
.conditions-roadmap-content-title {

}
.conditions-roadmap-content-text {
    font-size: 13px;
}