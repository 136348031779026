.slideInFromLeft {
    animation: ease-in-out 1.2s slideInFromLeft;
}
.slideInFromRight {
    animation: ease-in-out 1.2s slideInFromRight;
}
.slideInFromTop {
    animation: ease-in-out 1.2s slideInFromTop;
}
.slideInFromBottom {
    animation: ease-in-out 1.2s slideInFromBottom;
}
.scaleFrom0 {
    animation: ease-in-out 1.2s scaleFrom0;
}
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes scaleFrom0 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}