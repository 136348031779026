@media (max-width: 870px) {
    .home-header-top-title {
        justify-content: center !important;
        align-content: center !important;
        align-items: center !important;
    }
}
@media (max-width: 1000px) {
    .home-header-top-container {
        height: calc(100vh - 25vh - 50px) !important;
    }
    .home-header-top-container img {
        height: 100vh;
        width: auto;
    }
    .wrap {
        justify-self: center;
        align-self: center;
    }
}
@media (max-width: 770px) {
    .home-header-top-left-box {
        display: none;
    }
    .home-header-top-right-box {
        display: none;
    }
    .home-header-top-title-wrap {
        top: 0;
        height: calc(100vh - 50px) !important;
    }
}