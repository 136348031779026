@media (max-width: 870px) {
    .conditions-roadmap-items {
        width: 80vw;
        grid-template-columns: 1fr 1fr 1fr !important;
        grid-template-rows: 1fr 1fr 1fr 1fr !important;
        gap: 0px 10px !important;
        grid-template-areas:
    "conditions-raodmap-item11 conditions-raodmap-item12 conditions-raodmap-item13"
    "conditions-raodmap-item21 conditions-raodmap-item22 conditions-raodmap-item23"
    "conditions-raodmap-item14 conditions-raodmap-item15 conditions-raodmap-item16"
    "conditions-raodmap-item24 conditions-raodmap-item25 conditions-raodmap-item26" !important;
        height: 100% !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .conditions-raodmap-item14, .conditions-raodmap-item15, .conditions-raodmap-item16 {
        margin-top: 20px;
    }
}
@media (max-width: 470px) {
    .conditions-roadmap-items {
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr !important;
        gap: 0px 10px !important;
        grid-template-areas:
    "conditions-raodmap-item11 conditions-raodmap-item12"
    "conditions-raodmap-item21 conditions-raodmap-item22"
    "conditions-raodmap-item13 conditions-raodmap-item14"
    "conditions-raodmap-item23 conditions-raodmap-item24"
    "conditions-raodmap-item15 conditions-raodmap-item16"
    "conditions-raodmap-item25 conditions-raodmap-item26" !important;
        height: 100% !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .conditions-raodmap-item13, .conditions-raodmap-item14 {
        margin-top: 20px;
    }
}