.base-content-slider-wrap {
    overflow: hidden;
    height: 100%;
    width: 75vw !important;
}
.base-content-slider-elements {
    position: relative;
    display: flex;
    flex-direction: row;
    will-change: transform;
    transition: transform 0.7s ease-in-out;
    height: 100%;
    gap: 30px;
}