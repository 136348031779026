.int-with-bg {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 75vw !important;
    min-width: 75vw !important;
    max-width: 75vw !important;
}
.int-with-bg-container {
    position: relative;
    width: 100%;
    background: #5089C6;
}
.int-with-bg-container:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255,0.1) 2px,
            rgba(255, 255, 255,0.1) 5px,
            rgba(255, 255, 255,0.1) 5px
    );
}
.int-with-bg-container:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 10%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
            to right,
            rgba(255,255,255,0.1) 20%,
            rgba(255,255,255,0.2) 25% 50%,
            rgba(255,255,255,0.3) 50% 75%,
            rgba(255,255,255,0.5) 75%
    );
}
.int-with-bg-container h3 .icon {
    width: 23px;
    height: 23px;
    color: white;
}
.int-with-bg-container h3 {
    position: relative;
    z-index: 2;
    margin-left: 40px;
    margin-top: 20px;
    color: rgba(255,255,255,0.9);
    overflow: hidden;
    text-transform: uppercase;
}
.int-with-bg-container h3:after {
    position: relative;
    top: -6px;
    background-color: #ffffff;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.int-with-bg-container h3:after {
    left: 0.5em;
    margin-right: -50%;
}
.int-with-bg-container p {
    position: relative;
    z-index: 2;
    margin: 10px calc(0.1 * 75vw) 30px 40px;
    color: rgba(255,255,255,1);
}