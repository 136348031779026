@media (max-width: 870px) {
    .base-under-header {
        margin-top: 20px;
        margin-bottom: 20px;
        flex-direction: column !important;
        width: 100vw;
        height: 100% !important;
        gap: 20px;
        box-shadow: none;
    }
}