.vrtx-navbar {
    position: sticky;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 50px;
    background: #5089C6;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    /*box-shadow: 2px 2px 5px rgba(0,0,0,0.4);*/
    border-bottom: 5px solid #19D5CE;
}
.vrtx-navbar-fade {
    background: rgba(0, 0, 0, 0.7);
    transition: all 1s;
}
.vrtx-navbar-links {
    height: 100%;
    width: 75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
}
.vrtx-navbar-links div:nth-child(1) {
    margin-left: 0;
}
.vrtx-navbar-links div:nth-last-child(1) {
    margin-right: 0;
}

label #sign-bars, label #sign-times {
    width: 30px;
    height: 50px;
    color: #000000;
    float: right;
    line-height: 50px;
    cursor: pointer;
    display: none;
}
#vrtx-burger {
    display: none;
}

.vrtx-navbar-no-underline {
    border-bottom: none !important;
}

.vrtx-navbar .vrtx-navbar-logo {
    display: none;
}
.vrtx-navbar .vrtx-navbar-logo img {
    width: 80px;
    height: auto;
}