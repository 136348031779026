@media (max-width: 870px) {
    .int-with-bg {
        width: 80vw !important;
        min-width: 80vw !important;
        max-width: 80vw !important;
    }
    .int-with-bg-container {
        width: 100% !important;
    }
    .int-with-bg-container p {
        margin-right: 40px !important;
    }
}