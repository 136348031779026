.accommodation-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.accommodation-section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 120px;
}
.accommodation-container {
    height: 100%;
    width: fit-content;
}


