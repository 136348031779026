@media (max-width: 870px) {
    .contacts-section {
        margin-bottom: 0;
    }
    .contacts-container-wrap {
        flex-direction: column;
        height: 100%;
    }
    .contacts-right {
        width: 100%;
        order: 2;
    }
    .contacts-left {
        width: 100%;
        order: 1;
    }
}