.home-nostrification {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    padding-top: 40px;
    background: #EFEFEF;
}
.home-nostrification-content {
    padding-bottom: 120px;
    background: rgba(61, 178, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
}
