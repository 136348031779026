.base-text {
    width: 100%;
    border-left: 5px solid #26649E;
    padding-left: 15px;
    max-width: 75vw !important;
    margin-top: 40px;
    margin-bottom: 40px;
}
.base-text-text {
    color: rgba(0, 0, 0, 0.9);
}