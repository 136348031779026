@media (max-width: 870px) {
    .categories-page-switch-items {
        gap: 20px !important;
        height: 100%;
    }
    .categories-page-switch-item-wrap {
        width: calc(80vw / 3);
        height: 5vh;
    }
    .categories-page-switch-item {
        width: 100%;
        height: 100%;
    }
    .categories-page-top-items {
        display: flex;
        flex-wrap: wrap;
    }
    .categories-page-top-item {
        width: calc(80vw / 2);
        height: 20vh;
    }
}

@media (max-width: 470px) {
    .categories-page-switch-items {
        display: none;
    }
    .custom-categories-sticky-header {
        display: flex !important;
    }
}