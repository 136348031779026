.title-with-text-and-photo {
    border-left: 5px solid #26649E;
    padding-left: 15px;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 75vw !important;
    min-width: 75vw !important;
    max-width: 75vw !important;
}

.title-with-text-and-photo-title {
    margin-bottom: 20px;
}
.title-with-text-and-photo-title h3, .title-with-text-and-photo-title h2  {
    display: flex;
    flex-wrap: wrap;
    color: #223D6A;
    text-transform: uppercase;
}
.title-with-text-and-photo-title h3 span, .title-with-text-and-photo-title h2 span{
    color: #26649E;
}
.title-with-text-and-photo-text img {
    float: right;
    width: 40%;
    margin-top: 6px;
    margin-left: 25px !important;
    margin-bottom: 25px !important;
}