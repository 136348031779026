.slim-base-header {
    height: 30vh;
    background: #5089C6;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
}
.slim-base-header .vrtx-base-header-bg {
    position: absolute;
    z-index: 0;
    /*top: 0;*/
    width: 100%;
    /*height: 100%;*/
    min-height: 100%;
    filter: brightness(60%);
}
.slim-base-header-wrap {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.slim-base-header-content {
    position: relative;
    z-index: 1;
    width: 50%;
    gap: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    color: white;
}
.slim-base-header-content h1 {
    font-size: 42px;
}
.slim-base-header-content p {
    font-size: 19px !important;
}

