@media (max-width: 870px) {
    .form-footer-content {
        flex-direction: column;
    }
    .form-footer-right, .form-footer-left {
        width: 100%;
    }
}
@media (max-width: 670px) {
    .form-footer-pair {
        flex-direction: column;
    }
}