.sticky-nav-link-wrap {
    width: fit-content;
}
.sticky-nav-link-wrap:hover {
    transform: scale(1.1);
    transition: all 0.6s;
}
.sticky-nav-link-wrap a {
    position: relative;
    padding: 5px 0;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
}

.sticky-nav-link-wrap a:hover {
    color: rgba(255, 255, 255, 1);
    border-bottom: 3px solid #19D5CE;
}
.sticky-nav-link-wrap-active {
    color: rgba(255, 255, 255, 1);
    border-bottom: 3px solid #19D5CE;
}