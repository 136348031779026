.form-footer {
    background: #5089C6;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

}
.form-footer-content-wrap {
    width: 75vw;
    height: 100%;
}
.form-footer-content {
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.form-footer-left-wrap {
    height: fit-content;
    width: fit-content;
    padding-bottom: 40px;
    padding-top: 6px;
}
.form-footer-left {
    width: 30%;
    display: flex;
    flex-direction: column;
}
.form-footer-right {
    width: 70%;
    height: 100%;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    gap: 10px;
}
.form-footer-pair {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}
.form-footer-column-pair {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.form-footer-left a {
    width: fit-content;
    height: fit-content;
}
.form-footer-left a:hover {
    transition: 0.4s;
    transform: scale(1.05);
}
.form-footer-left a img {
    width: 100px;
    height: auto;
}
.form-footer-left a img:hover {
    transition: 0.4s;
    transform: scale(1.05);
}
.form-footer-left p {
    color: rgba(255,255,255,0.9) !important;
}
.form-footer-right h2 {
    color: white;
    margin-bottom: 12px;
    padding-top: 20px;
}

.form-footer-item {
    display: flex;
    align-content: center !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row;
    height: 10vh !important;
}
.form-footer-item i{
    font-size: 30px;
    color: #19D5CE;
}

.form-footer-text-one {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8) !important;
}

.form-footer-right .form-footer-input-box{
    height: 50px;
    width: 100%;
}
.form-footer-topic-text{
    font-size: 23px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8) !important;
}
.form-footer-right .form-footer-input-box input,
.form-footer-right .form-footer-message-box textarea{
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    resize: none;
    box-sizing: border-box;
    padding: 15px;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
}
.form-footer-right .form-footer-input-box input::placeholder,
.form-footer-right .form-footer-message-box textarea::placeholder{
    color: rgba(255, 255, 255, 0.4);
}
.form-footer-message-box textarea {
    font-family: 'Montserrat', 'Roboto', 'Arial';
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}
.form-footer-right .form-footer-message-box{
    min-height: 110px;
}
.form-footer-button {
    left: 0 !important;
    margin-top: 12px;
}
.form-footer-captcha-wrap {
    padding-top: 8px;
    padding-bottom: 8px;
}
.response-message {
    position: relative;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    padding: 15px;
    border-radius: 6px;
    color: white;
}
.response-message .icon {
    position: absolute;
    color: white;
    top: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
}
.response-message .icon:hover {
    transform: scale(1.1);
    transition: 0.4s;
}