@media (max-width: 870px) {
    .triple-photo {
        display: none;
        width: 80vw !important;
        max-width: 80vw !important;
        min-width: 80vw !important;
    }
    .triple-photo-content {
        flex-direction: column;
        height: 100vh;
    }
    .triple-photo-content img {
        width: 100%;
    }
}