.base-under-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 25vh;
}
.base-under-header:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 15vh;
    z-index: 3;
}
.base-under-header h2 {
    color: #223D6A;
}
