.categories6 {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.categories6 h1, .categories6 h2, .categories6 h3, .categories6 p {
    color: white;
}
.categories6-container {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    grid-auto-flow: row;
    grid-template-areas:
    "block11 block12 block13 block13"
    "block21 block21 block22 block23";
}
.block11 {grid-area: block11;}
.block12 {grid-area: block12;}
.block12 img {
    width: 100%;
    height: auto;
    min-height: 100%;
}
.block13 {
    grid-area: block13;
    background: #5089C6;
}
.block13 h1 {
    margin-bottom: 15px;
}
.block13 .icon:hover {
    color: #19D5CE !important;
    transform: scale(1.05);
    transition: 0.4s;
}
.block21 {grid-area: block21;}
.block21 h1 {
    margin-bottom: 15px;
}
.block21 img {
    width: 100%;
    height: auto !important;
    min-height: 100%;
}
.block22 {grid-area: block22;}
.block23 {grid-area: block23;}

.categories6-container .block {
    position: relative;
    overflow: hidden;
}
.categories6-container .block img {
    position: absolute;
    z-index: 0;
    height: 100%;
}
.categories6-container .block .overlay-blue {
    position: absolute;
    z-index: 1;
    background: #5089C6;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}
.categories6-container .block .overlay-green {
    position: absolute;
    z-index: 1;
    background: #19D5CE;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}
.categories6-container .block .block-content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.categories6-container .block .block-content p {
    font-size: 16px !important;
    font-style: oblique 0deg;
    margin-bottom: 20px;
}
.categories6-container .block .block-content .icon {
    width: 32px !important;
    height: 32px !important;
    color: white;
}
