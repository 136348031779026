@media (max-width: 1000px) {
    .base-nav-link {
        width: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    .base-nav-link:hover {
        clip-path: polygon(7% 0, 100% 0, 93% 100%, 0% 100%);
        transform: none;
        transition: 0.7s;
    }
    .base-nav-link a {
        font-size: 22px;
    }
    .base-nav-link-active-item {
        clip-path: polygon(7% 0, 100% 0, 93% 100%, 0% 100%);
    }
}