.vrtx-footer {
    width: 100%;
    min-height: 125px;
    background: #5089C6;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.4));
}
.vrtx-footer-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 75vw;
}
.vrtx-footer-container a {
    width: fit-content;
    height: fit-content;
}
.vrtx-footer-container a:hover {
    transition: 0.4s;
    transform: scale(1.05);
}
.vrtx-footer-container a img {
    width: 100px;
    height: auto;
}
.vrtx-footer-container a img:hover {
    transition: 0.4s;
    transform: scale(1.05);
}
.vrtx-footer-container p {
    color: rgba(255,255,255,1) !important;
}
.vrtx-footer-container div {
    margin-left: auto;
    color: rgba(255,255,255,1) !important;
}

