.vrtx-adv-header {
    height: calc(75vh - 125px);
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
}
.vrtx-adv-header-wrap {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: relative;
}
.vrtx-adv-header-bg {
    position: absolute;
    z-index: 0;
    /*top: 0;*/
    width: 100%;
    /*height: 100%;*/
    min-height: 100%;
    filter: brightness(60%);
}
.vrtx-adv-header-overlay {
    position: absolute;
    z-index: 1;
    background: #5089C6;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}
.vrtx-adv-header-content {
    position: relative;
    z-index: 1;
    width: 100%;
    gap: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    color: white;
}
.vrtx-adv-header-content .scroll-down {
    border: 1px solid white;
    color: #19D5CE;
    font-size: 14px;
    padding: 15px 30px;
    border-radius: 25px;
    background: transparent;
    text-transform: uppercase;
}
.vrtx-adv-header-content .scroll-down:hover {
    background: #19D5CE;
    color: white;
    transition: 0.4s ease-in-out;
    border-color: #19D5CE;
}
.vrtx-adv-header-content h1 {
    font-size: 42px;
    margin-bottom: -10px;
    text-transform: uppercase;
}
.vrtx-adv-header-content h2 {
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: -10px;
    color: white;
}
.vrtx-adv-header-content p {
    font-size: 19px !important;
}
.vrtx-adv-header-bg {
    position: absolute;
    z-index: 0;
    /*top: 0;*/
    width: 100%;
    /*height: 100%;*/
    min-height: 100%;
    filter: brightness(60%);
}
.vrtx-adv-header-wrap {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.vrtx-adv-header-content {
    position: relative;
    z-index: 1;
    width: 50%;
    gap: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    color: white;
}

.header-background-image-wrapper {
    position: relative;
    z-index: 1;
    width: 50%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.header-background-image {
    position: relative;
    filter: brightness(100%);
    height: 80%;
}
.vrtx-adv-header-content .icon {
    color: white;
    width: 32px;
    height: 32px;
}
.vrtx-adv-header-content .icon:hover {
    color: #19D5CE;
    transform: scale(1.05);
    transition: 0.5s;
}