.contacts-section {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.contacts-section-content {
    width: 100%;
    height: 100%;
}

.contacts-container-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: #5089C6;
}

.contacts-left {
    overflow: hidden;
    width: 50%;
    /*height: 100%;*/
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.contacts-right {
    width: 50%;
    height: 100%;
    padding: 40px;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}
.contacts-details {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 5vh;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: column;
}

.contacts-item-wrap{
    position: relative;
    z-index: 3;
    display: table;
    width: 100%;
    height: 10vh;
    /*-moz-box-shadow: 0 0 3px rgba(0,0,0,0.4);*/
    /*-webkit-box-shadow: 0 0 3px rgba(0,0,0,0.4);*/
    /*box-shadow: 0 0 3px rgba(0,0,0,0.4);*/
}
.contacts-item {
    display: flex;
    align-content: center !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row;
    height: 10vh !important;
    margin-left: 10px;
    margin-right: 10px;
}
.contacts-item i{
    font-size: 30px;
    color: #19D5CE;
}

.contacts-text-one {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8) !important;
    margin-left: 10px;
}

.contacts-right .contacts-input-box{
    height: 50px;
    width: 100%;
    margin-top: 12px;
}
.contacts-topic-text{
    font-size: 23px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8) !important;
}
.contacts-right .contacts-input-box input,
.contacts-right .contacts-message-box textarea{
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    resize: none;
    box-sizing: border-box;
    padding: 15px;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
}
.contacts-right .contacts-input-box input::placeholder,
.contacts-right .contacts-message-box textarea::placeholder{
    color: rgba(255, 255, 255, 0.4);
}
.contacts-message-box textarea {
    font-family: 'Montserrat', 'Roboto', 'Arial';
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}
.contacts-right .contacts-message-box{
    min-height: 110px;
}
.contacts-button {
    left: 0 !important;
    margin-top: 12px;
}
.contacts-input-pair {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    margin-top: 12px;
}
.contacts-captcha-input-box {
    width: 100%;
    margin-top: 0 !important;
}
.contacts-captcha-wrap {
    align-self: center;
}
.response-message {
    position: relative;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    padding: 15px;
    border-radius: 6px;
    color: white;
}
.response-message .icon {
    position: absolute;
    color: white;
    top: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
}
.response-message .icon:hover {
    transform: scale(1.1);
    transition: 0.4s;
}


