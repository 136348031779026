@media (max-width: 1000px)  {
    .vrtx-navbar {
        background: #287BE9 !important;
    }

    .vrtx-navbar-links {
        gap: 30px;
        position: absolute;
        width: 100% !important;
        height: calc(100vh - 50px);
        background: rgba(0,0,0,0.7);
        top: 50px;
        left: -100%;
        text-align: center;
        transition: .4s;
        z-index: 10;
    }
    .vrtx-navbar-links div:nth-child(6) {
        margin-right: unset;
        margin-bottom: auto;
    }

    .vrtx-navbar label #sign-bars {
        display: block;
    }

    .vrtx-navbar .vrtx-navbar-links div {
        display: block;
    }

    #vrtx-burger:checked ~ .vrtx-navbar-links {
        left: 0;
    }

    #vrtx-burger:checked ~ label #sign-bars {
        display: none;
    }

    #vrtx-burger:checked ~ label #sign-times {
        display: block;
    }

    .vrtx-navbar label {
        margin-right: 30px;
        margin-left: auto;
        background: #19D5CE;
        padding-left: 20px;
        padding-right: 20px;
    }

    .invisible {
        display: block !important;
    }

    #sign-bars, #sign-times {
        color: white !important;
    }

    .vrtx-navbar-links {
        flex-direction: column;
    }

    .vrtx-navbar .vrtx-navbar-logo {
        display: block;
        margin-right: auto;
        margin-left: 30px;
    }

    .vrtx-navbar-links div:nth-child(1) {
        margin-left: auto;
    }
    .vrtx-navbar-links div:nth-child(6) {
        margin-right: auto;
    }

}