.about-us-section {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    min-width: 100vw;
}


.about-us-container-content {
    height: 100%;
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #EFEFEF !important;
    padding-bottom: 100px;
}
.about-us-subsection {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}
.about-us-top {
    /*width: 100%;*/
    width: 100%;
    height: 100%;
    background: #EFEFEF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.about-us-middle {
    width: 100%;
    height: fit-content;
    background: white !important;
}
.about-us-bottom {
    height: fit-content;
    background: white;
    padding-top: 40px;
    padding-bottom: 120px;
}
