.programs-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    padding-top: 40px;
    background: #EFEFEF;
}
.programs-container-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    margin-top: 40px;
    background: white;
    padding-bottom: 120px;
}
