.list-with-bg {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 75vw;
    min-width: 75vw;
    max-width: 75vw;
}
.list-with-bg-container {
    position: relative;
    width: 100%;
    background: #5089C6;
}
.list-with-bg-container:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2),
            rgba(255,255,255,0.1) 2px,
            rgba(255,255,255,0.1) 5px,
            rgba(255,255,255,0.1) 5px
    );
}
.list-with-bg-container:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 12%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
            to right,
            rgba(255,255,255,0.1) 20%,
            rgba(255,255,255,0.2) 25% 50%,
            rgba(255,255,255,0.3) 50% 75%,
            rgba(255,255,255,0.5) 75%
    );
}
.list-with-bg-container h3 {
    position: relative;
    z-index: 2;
    margin-left: 45px;
    margin-top: 20px;
    color: rgba(255,255,255,0.9);
    overflow: hidden;
    text-transform: uppercase;
}
.list-with-bg-container h3 .icon {
    width: 23px;
    color: white;
}
.list-with-bg-container h3:after {
    background-color: #ffffff;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    /*vertical-align: middle;*/
    bottom: 10px;
    width: 100%;
}
.list-with-bg-container h3:after {
    left: 0.5em;
    margin-right: -50%;
}

.list-with-bg-container ul {
    list-style: none;
    width: 90%;
    height: 100%;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.list-with-bg-container ul li {
    margin-top: 7px;
    display: flex;
    color: white;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.list-with-bg-container ul li span {
    position: relative;
    font-size: 24px;
    margin-right: 20px;
    color: white;
}