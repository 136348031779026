.application-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #EFEFEF;
    min-height: 100vh;
}
.application-title {
    align-self: center;
}
.application-title h2 {
    color: rgba(255,255,255,0.8);
    text-transform: uppercase;
}
.application-container {
    height: 100%;
    width: 85vw;
    margin-top: 100px;
    margin-bottom: 100px;
    background: #5089C6;
    box-shadow: 0 0 7px rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px;
}

.application-input-pair {
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.application-input-pair-column {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50%;
}
.application-input-box{
    height: 50px;
    width: 100%;
}
.application-input-box input,
.application-message-box textarea {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    resize: none;
    box-sizing: border-box;
    padding: 15px;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
}
.application-message-box {
    width: 50%;
}
.application-message-box textarea {
    height: 140px;
}
.application-input-box input::placeholder,
.application-message-box textarea::placeholder{
    color: rgba(255, 255, 255, 0.4);
}
.application-message-box textarea {
    font-family: 'Montserrat', 'Roboto', 'Arial';
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}

.application-container-select {
    display: flex;
    flex-direction: column;
}
.application-container-select label {
    color: rgba(255, 255, 255, 0.8) !important;
    margin-bottom: 10px;
    font-size: 16px;
}
.application-container-select select {
    background: transparent;
    box-sizing: border-box;
    padding: 15px;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    color: rgba(255, 255, 255, 0.4) !important;
    resize: none;
}
.application-container-select select option {
    background: rgba(0, 0, 0, 0.3);
}
.application-button {
    width: 50%;
    align-self: center;
}
.error {
    border: #F55C47 1px solid !important;
}
.application-captcha-wrap {
    align-self: center;
}
.response-message {
    position: relative;
    border: rgba(255, 255, 255, 0.4) 1px solid;
    padding: 15px;
    border-radius: 6px;
    color: white;
}
.response-message .icon {
    position: absolute;
    color: white;
    top: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
}
.response-message .icon:hover {
    transform: scale(1.1);
    transition: 0.4s;
}


