@media (max-width: 470px) {
    .base-sticky-header-list-item {
        margin: 5px !important;
    }
}

@media (max-width: 640px) {
    .base-sticky-header-list-item {
        margin: 10px !important;
    }
}

@media (max-width: 870px) {
    .base-sticky-header-list-item {
        margin: 12px !important;
    }
}