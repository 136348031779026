.text-with-background {
    position: relative;
    z-index: 0;
    height: fit-content;
    margin-top: 40px;
    margin-bottom: 40px;
    background: #5089C6;
    overflow: hidden;
    width: 75vw;
    max-width: 75vw;
    min-width: 75vw;
}
.text-with-background:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2),
            rgba(255,255,255,0.1) 2px,
            rgba(255,255,255,0.1) 5px,
            rgba(255,255,255,0.1) 5px
    );
}
.text-with-background .icon {
    position: absolute;
    z-index: 2;
    font-size: 35vh;
    color: #223D6A;
    transform: rotate(15deg);
    left: 7%;
    width: 200px;
    aspect-ratio: 1;
}
.text-with-background p {
    color: rgba(255, 255, 255, 1);
    position: relative;
    z-index: 4;
    margin: 40px;
    text-align: center;
}