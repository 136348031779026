.sticky-header-title {
    min-height: 50px;
    width: 100%;
    background: #5089C6;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-end;
    box-shadow: 0 2px 2px 2px rgba(0,0,0,0.4);
}
.sticky-header-title h2 {
    color: white;
    text-transform: uppercase;
    /*margin: 20px;*/
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}