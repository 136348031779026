@media (max-width: 870px) {
    .title-with-two-texts {
        width: 80vw !important;
        min-width: 80vw !important;
        max-width: 80vw !important;
    }
    .title-with-two-texts-text {
        gap: 20px !important;
        flex-direction: column !important;
    }
}