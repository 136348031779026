.home-header-top-container {
    position: absolute;
    text-align: center;
    height: calc(100vh - 25vh - 75px - 50px);
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: #5089C6;
}

.home-header-top-container img {
    top: 0;
    position: absolute;
    width: 100%;
    min-height: 100%;
    filter: brightness(90%);
}


.home-header-top-left-box {
    background: #5089C6;
    clip-path: polygon(0% 0%, 45% 0%, 28% 100%, 0% 100%);
    height: 100%;
    width: 100%;
    z-index: 3;
}

.home-header-top-right-box {
    position: relative;
    background: #5089C6;
    clip-path: polygon(64% 0%, 100% 0%, 100% 100%, 48% 100%);
    height: 100%;
    width: 100%;
    z-index: 3;
}

/*Title*/
.home-header-top-title-wrap {
    position: absolute;
    z-index: 3 !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.home-header-top-title {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: white;
    width: 75%;
}
/*.home-header-top-title h1 {*/
/*    font-size: 36px;*/
/*}*/
.home-header-top-title p {
    padding-top: 19px;
    font-family: "Montserrat", "Roboto", "Arial";
    font-size: 20px;
    color: white !important;
}
.home-header-top-title h1 {
    font-family: "Montserrat", "Roboto", "Arial";
    font-size: 42px;
    text-transform: uppercase;
}
/*Title End*/

/*Button*/
.wrap {
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button {
    min-width: 200px;
    min-height: 50px;
    font-family: "Montserrat", "Roboto", "Arial";
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: white;
    background: #4FD1C5;
    background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.home-header-top-title a::before {
    content: '';
    border-radius: 1000px;
    min-width: calc(200px + 12px);
    min-height: calc(50px + 12px);
    border: 6px solid #19D5CE;
    box-shadow: 0 0 60px rgba(0,255,203,.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
    -webkit-transition: all .3s ease-in-out 0s;
}
.button:hover, .button:focus {
    transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
}
.home-header-top-title a:hover::before, a:focus::before {
    opacity: 1;
}
.home-header-top-title a::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
    -webkit-animation-name: ring;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    will-change: ring;
}
.home-header-top-title a:hover::after, a:focus::after {
    animation: none;
    -webkit-animation: none;
    display: none;
}
@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
    100% {
        width: 200px;
        height: 200px;
        opacity: 0;
    }
}
@-webkit-keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
    100% {
        width: 200px;
        height: 200px;
        opacity: 0;
    }
}
div #preload {
    display: none;
}
/*Button end*/







