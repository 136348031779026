.base-title-with-text {
    border-left: 5px solid #26649E;
    padding-left: 15px;
    max-width: 75vw !important;
    margin-top: 40px;
    margin-bottom: 40px;
}
.base-title-with-text-title {
    margin-bottom: 20px;
}
.base-title-with-text-title h3, .base-title-with-text-title h2 {
    display: flex;
    flex-wrap: wrap;
    color: #223D6A;
    text-transform: uppercase;
}
.base-title-with-text-title h3 span, .base-title-with-text-title h2 span{
    color: #26649E;
}