@media (max-width: 870px) {
    .vrtx-fast-navigation-container {
        width: 100% !important;
        flex-direction: column;
    }

    .vrtx-fast-navigation-disappear {
        display: none;
    }

    .vrtx-fast-navigation-box {
        width: 100%;
        height: calc(100% / 4);
    }
    .vrtx-fast-navigation-box:hover {
        clip-path: polygon(7% 0, 100% 0, 93% 100%, 0% 100%) !important;
    }
}
@media (max-width: 770px) {
    .vrtx-fast-navigation {
        height: 30vh
    }
}