.vrtx-list {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 75vw;
    min-width: 75vw;
    max-width: 75vw;
}

.vrtx-list ul {
    list-style: none;
    width: 100%;
    height: 100%;
}
.vrtx-list ul li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    font-size: 17px;
    line-height: 25px;
}
.vrtx-list ul li span {
    position: relative;
    font-size: 20px;
    margin-right: 20px;
    color: #26649E;
}

.vrtx-list-title {
    margin-bottom: 20px;
}
.vrtx-list-title h3, .vrtx-list-title h2  {
    display: flex;
    flex-wrap: wrap;
    color: #223D6A;
    text-transform: uppercase;
}
.vrtx-list-title h3 span, .vrtx-list-title h2 span{
    color: #26649E;
}

