.triple-photo {
    width: 75vw !important;
    max-width: 75vw !important;
    min-width: 75vw !important;
    margin-top: 40px;
    margin-bottom: 40px;
}
.triple-photo .triple-photo-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
}
.triple-photo-content img {
    width: calc(100% / 3);
    box-shadow: 0 0 4px rgba(0,0,0,0.4);
}