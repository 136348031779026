.base-wo-titles-with-two-texts {
    width: 100%;
    max-width: 75vw !important;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.base-wo-titles-with-two-texts-left, .base-wo-titles-with-two-texts-right  {
    width: 50%;
    border-left: 5px solid #26649E;
    padding-left: 15px;
}
.base-wo-titles-with-two-texts-title {
    margin-bottom: 20px;
}
.base-wo-titles-with-two-texts-title h3, .base-wo-titles-with-two-texts-title h2 {
    display: flex;
    flex-wrap: wrap;
    color: #223D6A;
    text-transform: uppercase;
}
.base-wo-titles-with-two-texts-title h2 span, .base-wo-titles-with-two-texts-title h3 span{
    color: #26649E;
}
.base-wo-titles-with-two-texts-list {
    margin-top: 20px;
}

