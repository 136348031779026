@media (max-width: 870px) {
    .slim-base-header {
        height: calc(100vh - 50px) !important;
        padding-bottom: 50px;
        padding-top: 50px;
    }
    .vrtx-base-header-bg {
        height: 100% !important;
        min-width: 100% !important;
        width: auto !important;
    }
    .slim-base-header-wrap {
        flex-direction: column;
        width: 80%;
    }

    .slim-base-header-content {
        width: 100%;
        order: 2;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .slim-base-header-content p {
        text-align: center;
    }

    .header-background-image-wrapper {
        width: 100%;
        order: 1;
    }
}