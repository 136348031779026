.photo-and-text {
    max-width: 75vw;
    min-width: 75vw;
    width: 75vh;
    margin-top: 40px;
    margin-bottom: 40px;
    border-right: 5px solid #26649E;
    padding-right: 15px;
}
.photo-and-text img {
    margin-top: 6px;
    box-shadow: 0 0 7px rgba(0,0,0,0.4);
    float: left
}