.title-with-two-texts {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    border-left: 5px solid #26649E;
    padding-left: 15px;

    width: 75vw;
    min-width: 75vw;
    max-width: 75vw;
}
.title-with-two-texts-title h2, .title-with-two-texts-title h3 {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: #223D6A;
    margin-bottom: 20px;
}
.title-with-two-texts-title h2 span, .title-with-two-texts-title h3 span{
    color: #26649E;
}
.title-with-two-texts-text {
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: row;
    gap: 80px;
}
