.base-nav-link {
    margin: auto;
    height: 50px;
}
.base-nav-link:hover {
    transform: scaleX(1.05);
    transition: all 0.4s;
}

.base-nav-link a {
    position: relative;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.base-nav-link a:hover {
    color: rgba(255, 255, 255, 1);
    background: #19D5CE;
}

.base-nav-link-active-item {
    background: #19D5CE;
    height: 50px !important;
    color: white !important;
}