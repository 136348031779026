.above-navbar {
    width: 100%;
    min-height: 75px;
    background: #5089C6;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.above-navbar-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 75vw;
}
.above-navbar-container a {
    width: fit-content;
    height: fit-content;
    color: white;
}
.above-navbar-container a:hover {
    transition: 0.4s;
    transform: scale(1.02);
    color: #19D5CE;
}
.above-navbar-container a img {
    width: 100px;
    height: auto;
}
.above-navbar-container a img:hover {
    transition: 0.4s;
    transform: scale(1.05);
}
.above-navbar-container p {
    color: white !important;
}
.above-navbar-container .email {
    margin-left: auto;
    color: white !important;
}
.above-navbar-container .icon {
    font-size: 28px;
    color: white;
    width: 28px;
    height: 28px;
}
.above-navbar-container .icon:hover {
    color: #19D5CE;
    transform: scale(1.02);
    transition: 0.4s;
}

