@media (max-width: 870px) {
    .accommodation-item {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 80vw;
        min-width: 80vw;
        max-width: 80vw;
    }
    .accommodation-item-right img {
        height: fit-content;
    }
    .accommodation-item-left {
        width: 100% !important;
    }
}