.accommodation-item {
    min-height: 50vh;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.accommodation-item i {
    color: #26649E;
}
.accommodation-item {
    display: grid;
    grid-template-columns: 0.50fr 0.50fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas:
    "accommodation-item-left accommodation-item-right";

    width: 100%;
}
/*accommodation-item-left*/
.accommodation-item-left {
    grid-area: accommodation-item-left;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;

    width: 90%;
    height: 100%;
}
/*accommodation-item-left-bottom*/
.accommodation-item-left .accommodation-item-left-bottom {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 18px;
}

.accommodation-item-left .accommodation-item-left-bottom h3 {
    color: #223D6A;
}
.accommodation-item-left .accommodation-item-left-bottom ul {
    list-style: none;

    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    margin-top: 20px;
}
.accommodation-item-left .accommodation-item-left-bottom ul li {
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 5px;
}
.accommodation-item-left .accommodation-item-left-bottom ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #26649E; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}
/*accommodation-item-left-bottom END*/
/*accommodation-item-left END*/

/*accommodation-item-right*/
.accommodation-item-right {
    grid-area: accommodation-item-right;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    align-items: flex-start;

    width: 100%;
    height: 100%;

}
.accommodation-item {
    width: 75vw;
    min-width: 75vw;
    max-width: 75vw;
}
.accommodation-item-right img {
    margin-top: 6px;
    width: 100%;
    height: 100%;
}
.accommodation-item-right .accommodation-item-right-address div {
    margin-left: 15px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

}
.accommodation-item-right .accommodation-item-right-address .icon,
.accommodation-item-right .accommodation-item-right-transport .icon {
    width: 24px;
    height: 24px;
    color: #26649E;
}

.accommodation-item-right .accommodation-item-right-transport ul {
    list-style: none;
    margin-left: 10px;
}
.accommodation-item-right .accommodation-item-right-transport,
.accommodation-item-right .accommodation-item-right-address {
    margin-top: 20px;
}
.accommodation-item-right .accommodation-item-right-transport,
.accommodation-item-right .accommodation-item-right-address {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.accommodation-item-right .accommodation-item-right-transport ul li {
    margin-bottom: 10px;
}
/*accommodation-item-right END*/
.custom-title-with-text {
    margin-top: 0 !important;
}



