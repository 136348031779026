.vrtx-switch {
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    margin-bottom: 20px;
    width: 75vw;
}

.vrtx-switch-top {
    width: 100%;
}
.vrtx-switch-top-content {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.vrtx-switch-top-content {
    text-transform: uppercase;
    color: #223D6A;
}
.vrtx-switch-top-content h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 26px;
}
.vrtx-switch-top-button-group {
    /*min-width: 300px;*/
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 20vw;
}
.vrtx-switch-top-button-group button:nth-child(1) {
    background: #26649E;
}
.vrtx-switch-top-button-group button:nth-child(2) {
    background: lightgray;
}
.vrtx-switch-top-button-group button {
    height: 6px;
    /*min-width: 150px;*/
    width: 50%;
    border: 0;
    border-radius: 6px;
    margin-top: 5px;
}
.vrtx-switch-top-button-group button:hover {
    transform: scale(1.02);
    transition: 0.5s;
}
.vrtx-switch-bottom {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 80px;
}
.vrtx-switch-bottom div {
    width: 50%;
    text-align: center;
}