@media (max-width: 870px) {
    .title-with-text-and-photo {
        width: 80vw !important;
        min-width: 80vw !important;
        max-width: 80vw !important;
    }
    .title-with-text-and-photo-text img {
        float: top;
        width: 100% !important;
        margin-left: 10px;
        margin-bottom: 10px;
    }
}