@media (max-width: 870px) {
    .vrtx-switch {
        width: 80vw !important;
    }
    .vrtx-switch-bottom {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 30px;
    }
    .vrtx-switch-bottom div {
        width: 100%;
    }
    .vrtx-switch-top-button-group {
        width: 50vw !important;
    }
    .vrtx-switch-top-button-group button {
        width: 50% !important;
    }
}