.base-wo-titles-with-two-lists {
    width: 100%;
    max-width: 75vw !important;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.base-wo-titles-with-two-lists-left, .base-wo-titles-with-two-lists-right  {
    width: 50%;
}
.base-wo-titles-with-two-lists-title h3 {
    display: flex;
    flex-wrap: wrap;
    color: #223D6A;
    text-transform: uppercase;
}
.base-wo-titles-with-two-lists-title h3 span{
    color: #26649E;
}
.base-wo-titles-with-two-lists-list {
    margin-top: 20px;
}

.base-wo-titles-with-two-lists ul {
    list-style: none;
    width: 100%;
    height: 100%;
}
.base-wo-titles-with-two-lists ul li {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.base-wo-titles-with-two-lists ul li span {
    position: relative;
    font-size: 24px;
    margin-right: 20px;
    color: #26649E;
}