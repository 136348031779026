@media (max-width: 870px) {
    .categories6 {
        height: 100%;
    }
    .categories6-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 0 0;
        grid-auto-flow: row;
        grid-template-areas:
    "block11"
    "block12"
    "block13"
    "block21"
    "block22"
    "block23";
    }
    .categories6-container .block img {
        /*height: 100% !important;*/
        /*width: auto !important;*/
        /*min-width: 100%;*/
        width: 100%;
        height: auto;
        min-height: 100%;
    }
}