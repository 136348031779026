.vrtx-fast-navigation {
    position: absolute;
    bottom: 0;
    width: 100%;

    text-align: center;

    z-index: 8;
    background: #EFEFEF;
    filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.4));

    height: calc(25vh + 1px);

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.vrtx-fast-navigation-container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.vrtx-fast-navigation-box {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    width: calc(100% / 4);

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.vrtx-fast-navigation-box:hover {
    /*background: #19D5CE;*/
    transition: all 1s;
    /*clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);*/
    clip-path: polygon(20% 0%, 100% 0%, 81.5% 100%, 1.5% 100%);
    background: linear-gradient(90deg, rgba(129,230,217,1) 0%, #19D5CE 100%);
}
.vrtx-fast-navigation-box-active {
    clip-path: polygon(20% 0%, 100% 0%, 81.5% 100%, 1.5% 100%);
    background: linear-gradient(90deg, rgba(129,230,217,1) 0%, #19D5CE 100%);
}
.vrtx-fast-navigation-box-active div {
    color: white !important;
}
.vrtx-fast-navigation-box:hover > div {
    color: white;
}
.vrtx-fast-navigation-box div {
    font-size: 14px;
    text-transform: uppercase;
    color: #223D6A;
    font-weight: 600;
}


