.base-sticky-header-list {
    position: sticky !important;
    top: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background: #5089C6;
    width: 100%;
    min-width: 100%;
    max-width: 177.5vh;
    z-index: 3;
    overflow: hidden;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,0.4);
}
.base-sticky-header-list .base-sticky-header-list-item:first-child {
    margin-left: 0 !important;
}
.base-sticky-header-list .base-sticky-header-list-item:last-child {
    margin-right: 0 !important;
}
.base-sticky-header-list-item {
    margin: 20px;
}


