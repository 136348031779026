.vrtx-path {
    position: relative;
    width: 75vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-bottom: -50px;
}
.vrtx-path .vrtx-path-item {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    align-items: center;
}
.vrtx-path a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    color: #223D6A !important;
    margin-left: 5px;
    white-space: nowrap;
}
.vrtx-path a:hover {
    border-bottom: 2px solid #19D5CE;
}
.vrtx-path .icon  {
    color: #19D5CE;
    width: 15px;
    height: 15px;
    margin-left: 5px;
}